.nav-container{
    position: fixed;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    align-items: center;
    background-color: var(--darkgrey);
    z-index: 300;
}

.logo{
    height: 30px;
    padding-right: 50px;
    border-right: 1px solid black;
    filter: invert(100%) sepia(0%) saturate(2544%) hue-rotate(329deg) brightness(116%) contrast(90%);
    z-index: 400;
}

.navlink {
    width: 55%;
}

.navlink ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.navlink ul div a {
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--textcolor);
    text-transform: uppercase;
}

.navlink ul div a:hover {
    color: var(--darkyellow);
    transition: 0.1s ease-in;
}

.navlink ul div a.active {
    color: var(--darkyellow);
}

.nav-toggle{
    display: none;
    padding-left: 2rem;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    color: var(--textcolor);
}

.cta-container {
    display: flex;
    margin-left: auto;
    align-items: center;
}

.message {
    height: 30px;
    width: 30px;
    filter: invert(70%) sepia(87%) saturate(980%) hue-rotate(359deg) brightness(97%) contrast(97%);
    margin-right: 5px;
}

.cta-container a{
    font-weight: 700;
    text-decoration: none;
    color: var(--textcolor);
    text-transform: uppercase;
    
}

@media (max-width: 750px){
    .navlink {
        position: fixed;
        inset: 0 30% 0 0;
        background: var(--darkyellow);
        backdrop-filter: 1rem;
        padding: 4.25rem 0;
        transform: translateX(-110%);
        transition: 0.5s ease;

        box-shadow: 10px 0 0 rgb(38, 38, 38, 0.7);
    }

    .navlink[data-visible='true']{
        transform: translateX(0%);
    }
    
    .navlink ul {
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
    }

    .navlink ul div {
        width: 100%;
    }

    .navlink ul div:hover a{
        background-color: var(--darkgrey);
        color: var(--darkyellow);
        transition: 0.5s cubic-bezier(.47,1.64,.41,.84);
        padding-left: 30px;
    }

    .navlink ul div a{
        display: block;
        color: var(--darkgrey);
        font-size: 2.5rem;
        padding: 1rem;
        cursor: pointer;
    }

    .nav-toggle{
        display: block;
        z-index: 200;
        font-weight: 700;
        transition: 0.3s ease-in-out;
    }
    .toggle-open{
        color: var(--darkgrey);
    }
}

@media (max-width: 440px){
    .navlink ul div a{
        font-size: 2rem;
    }
}

@media (min-width: 1500px){
    .navlink{
        width: 30%;
    }
}