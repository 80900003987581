@font-face {
  font-family: "JosefinSans";
  src: local("JosefinSans"),
  url(./fonts/JosefinSans.ttf) format('truetype');
}

html{
  background-color: var(--darkgrey);
  scroll-padding-top: 60px;
}

body {
  margin: 0;
  font-family: "JosefinSans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--darkyellow);
  border: 1px var(--darkgrey) solid;
  border-radius: 10px;
}

/* Lenis recommended CSS */
/* html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
} */