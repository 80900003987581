.footer-container {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;

    background-color: var(--darkgrey);
    color: var(--textcolor);
}
.footer-container .heart {
    color: var(--darkyellow);
}

.footer-container .contacts{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
}

.footer-container .contacts a:link {
    text-decoration: none;
    color: var(--textcolor);
    transition: 0.5s ease;
}

.footer-container a:visited{
    text-decoration: none;
    color: var(--textcolor);
}

.footer-container .contacts a:hover{
    color: var(--darkyellow);
}

@media (max-width: 1000px){
    .footer-container .contacts{
        width: 80%;
    }
}