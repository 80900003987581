.contact-container {
    background-color: var(--darkgrey);
    color: var(--textcolor);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 600px;
}

.contact-container .blob{
    height: 50%;
    width: 90%;
    position: absolute;
    z-index: 0;
    overflow: hidden;
}

.contact-container h1 {
    z-index: 1;
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 3rem;
    text-transform: uppercase;
}

.form-container {
    z-index: 1;
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.form-container form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-bottom: 30px;
}

.form-container form input {
    margin-bottom: 20px;
    background-color: rgba(242, 242, 242, 0.8);
    border: none;
    border-radius: 10px;
    color: var(--darkgrey);
    text-indent: 15px;
}
.form-container form textarea {
    min-height: 200px;
    height: 200px;
    margin-bottom: 20px;
    background-color: rgba(242, 242, 242, 0.9);
    border: none;
    border-radius: 10px;
    color: var(--darkgrey);
    text-indent: 15px;
}

.form-container form input::placeholder, textarea::placeholder{
    text-indent: 15px;
}

.form-container form input:focus{
    background-color: var(--textcolor);
}

.form-container form textarea:focus{
    background-color: var(--textcolor);
}

#submit {
    margin: auto;
    font-size: 1.25rem;
    font-weight: 800;
    width: 100px;
    height: 40px;
    background-color: var(--darkgrey);
    color: var(--darkyellow);
    border: 2px solid var(--darkyellow);
    border-radius: 10px;
    text-indent: 0 !important;
    transition: all 0.35s;
}

#submit:hover{
    background-color: var(--darkyellow);
    color: var(--darkgrey);
}

#submit.submit-animation{
    width: 40px;
    border-radius: 50%;
    color: var(--darkyellow);
    background-color: var(--textcolor);
    transform: rotate(360deg);
    text-indent: 0 !important;
    border: none;
}

@media (max-width: 576px){
    .contact-container{
        height: 600px;
    }
    .form-container form{
        width: 80%;
    }
}