.main-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--darkgrey);
    color: var(--textcolor);
}

.itsme{
    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 100;
}

.itsme h1{
    font-size: 4rem;
    letter-spacing: -3px;
}

.itsme h2 {
    font-size: 2rem;
    margin: 0;
}

.itsme>h1, .itsme>h2 {
    text-shadow: 3px 2px #262626;
    transform: rotate(-10deg);
}

.main-container .blob{
    height: 70%;
    position: absolute;
    z-index: 0;
    overflow: hidden;
}

@media (max-width: 555px){
    .itsme h1 {
        font-size: 3rem;
    }
    .itsme h2 {
        font-size: 1.5rem;
    }
}