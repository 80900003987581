.skills-container{
    cursor: none;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;

    background-color: var(--darkyellow);
    color: var(--darkgrey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.skills-container h1 {
    margin-bottom: 30px;
    font-size: 3rem;
    text-transform: uppercase;
}

.skills-container .skill-name {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 2rem;
    padding-top: 10px;
}

.skills-box{
    width: 80%;
}

.skill-row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.skill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.skill-icon {
    margin-bottom: 10px;
}

.skill-icon path {
    fill: var(--darkgrey);
}

.skill-icon g {
    fill: var(--darkgrey);
}

@media (max-width: 905px){
    .skill-icon{
        width: 125px;
        height: 125px;
    }
    .skills-container .skill-name {
        font-size: 1.25rem;
        font-weight: 800;
    }
}

@media (max-width: 700px){
    .skill-row {
        display: block;
    }
}

/* CURSOR Animation */

#cursor {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 60px;
    transform: translate(-30px, -30px);
    background-color: var(--darkyellow);
    border-radius: 50%;
    pointer-events: none;
    z-index: 999;
    scale: 0;
    backface-visibility: hidden;
    visibility: hidden;
    transition: 0.03s ease;
    mix-blend-mode: difference;
}