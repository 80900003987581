.projects2{
    background-color: var(--textcolor);
}

.projects2-container{
    width: 80%;
    height: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.projects2-container h1 {
    font-size: 3rem;
    text-transform: uppercase;
    color: var(--darkgrey);
    text-align: center;
}

.projects2-container .cards-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;
}

.projects2-container .card-item {
    padding: 30px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: none;
}

.projects2-container .card:hover {
    filter: contrast(1.2);
    top: -5px;
    cursor: pointer;
}

.projects2-container .card:hover  > .card-content {
    box-shadow: 0 20px 20px -14px rgba(0, 0, 0, 1);
}

.projects2-container .card:hover  > .card-content > .title {
    padding-right: 50px;
}

.projects2-container .card:hover  > .card-content > .sub-title {
    padding-right: 50px;
}

.projects2-container .card-content {
    padding-top: 20px;
    background-color: var(--darkgrey);
    color: var(--textcolor);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 20px 20px -14px rgba(0, 0, 0, 0.5);
    transition: all 0.5s cubic-bezier( 0.79, 0.33, 0.14, 0.53 );
}

.projects2-container .card{
    top: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.5s cubic-bezier( 0.79, 0.33, 0.14, 0.53 );
}

.projects2-container img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 0 0;
    background-repeat: no-repeat;
    top: 0;
    vertical-align: middle;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: all 0.35s ease-in-out;
}

.projects2-container .title{
    padding-right: 30px;
    text-align: right;
    font-size: 2rem;
    transition: all 0.5s cubic-bezier( 0.79, 0.33, 0.14, 0.53 );
    transition-delay: 0.10s;
}

.projects2-container .sub-title{
    color: var(--darkyellow);
    padding-right: 30px;
    text-align: end;
    text-transform: uppercase;
    letter-spacing: 5px;
    transition: all 0.5s cubic-bezier( 0.79, 0.33, 0.14, 0.53 );
    font-size: 0.75rem;
}

.projects2-container .description{
    display: none;
}

.projects2-container .project-links-container{
    display: none;
    margin: auto;
    margin-top: 25px;
    align-items: center;
}

.projects2-container .project-links {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.projects2-container .project-links-container a{
    text-decoration: none;
}

.projects2-container .project-links-container a:link {
    text-decoration: none;
    color: var(--textcolor);
    transition: 0.5s ease;
}

.projects2-container .project-links-container a:visited{
    text-decoration: none;
    color: var(--textcolor);
}

.projects2-container .github-link{
    display: none;
    text-align: center;
    padding: 5px;
    width: 80px;
    font-weight: 800;
}

.projects2-container .site-link{
    display: none;
    width: 80px;
    text-align: center;
    padding: 5px;
    font-weight: 800;
}

/** Expanded card styles  */

.projects2-container .card-item.expanded{
    background-color: var(--darkgrey);
    padding: 0;
    transition: all 0.35s ease-in-out;
}

.projects2-container .card-item.expanded .card-content{
    box-shadow: none;
}

.projects2-container .card-item.expanded .title{
    opacity: 0;
    transition: all 0.5s cubic-bezier( 0.79, 0.33, 0.14, 0.53 );
    transition-delay: 0.5s;
}
.projects2-container .card-item.expanded .sub-title{
    opacity: 0;
    transition: all 0.5s cubic-bezier( 0.79, 0.33, 0.14, 0.53 );
    transition-delay: 0.4s;
}

.projects2-container .card-item.expanded .project-links-container{
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: all 0.5s ease;
    transition-delay: 0.65s;
}

.projects2-container .card-item.expanded .description{
    display: flex;
    text-align: center;
    margin-top: 25px;
    font-size: 1.25rem;
    width: 60%;
    margin: auto;
    margin-bottom: 50px;

    opacity: 0;
    transition: all 0.5s ease;
    transition-delay: 0.6s;
}

.projects2-container .card-item.expanded .description span{
    font-weight: 800;
    font-size: 1.35rem;
    color: var(--darkyellow);
}


.projects2-container .card-item.expanded .github-link{
    display: block;
    opacity: 0;
    
    transition: all 0.5s ease;
    transition-delay: 0.7s;
}

.projects2-container .card-item.expanded .site-link{
    display: block;
    opacity: 0;

    transition: all 0.5s ease;
    transition-delay: 0.8s;
}

.projects2-container .close-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 800;
    position: fixed;
    z-index: 10000;
    top: 35px;
    right: 35px;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    background-color: var(--darkyellow);
    opacity: 0;
    transition: all 0.5s ease;
    /* transition-delay: 0.8s; */
}

.projects2-container .close-button:hover{
    transform: scale(1.5);
    transition-delay: 0s;
}

/** Animation after expansion */
.projects2-container .card-item.expanded-animation img{
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 600px;
    border-radius: 15px;
}

.projects2-container .card-item.expanded-animation .title{
    text-align: center;
    opacity: 1;
    padding: 0;
}

.projects2-container .card-item.expanded-animation .sub-title{
    text-align: center;
    opacity: 1;
    padding: 0;
}

.projects2-container .card-item.expanded-animation .project-links-container{
    opacity: 1;
}

.projects2-container .card-item.expanded-animation .description{
    display: block;
    opacity: 1;
}

.projects2-container .card-item.expanded-animation .github-link{
    display: block;
    opacity: 1;
}

.projects2-container .card-item.expanded-animation .site-link{
    display: block;
    opacity: 1;
}

.projects2-container .card-item.expanded-animation .close-button{
    opacity: 1;
}

/**Disable hover while expanded  */
.projects2-container .card-item.expanded .card:hover{
    filter: none;
    top: 0;
    cursor: auto;
}

.projects2-container .card-item.expanded .card:hover > .card-content{
    box-shadow: none;
}

.projects2-container .card-item.expanded .card:hover > .card-content > .title{
    padding-right: 0px;
}

.projects2-container .card-item.expanded .card:hover > .card-content > .sub-title{
    padding-right: 0px;
}

/** Media queries */

@media (min-width: 800px) {
    .projects2-container .card-item {
      width: 100%;
    }
  }
  
@media (min-width: 1200px) {
    .projects2-container .card-item {
      width: 50%;
    }
  }