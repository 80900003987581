.about-container{
    background-color: var(--darkyellow);
    color: var(--darkgrey);
    width: 100%;
    height: 100%;
    padding: 90px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-container p {
    font-size: 6rem;
    margin-left: auto;
    letter-spacing: -5px;
    line-height: 6rem;
}

.about-container .about-me {
    display: block !important;
    margin: auto;
    width: 400px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.5;

    cursor: pointer;
    transition: 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.about-me:hover {
    letter-spacing: 5px;
    opacity: 1;
}

#split{
    font-kerning: none;
}

/* About modal */
.about-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 1000px;
    max-width: 80%;
    max-height: 95%;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 350ms ease-in-out;

    border: 3px solid white;
    border-radius: 25px;
    z-index: 9999;
    background-color: var(--darkgrey);
    color: var(--textcolor);
    overflow-y: scroll;
}

.about-modal::-webkit-scrollbar {
    width: 8px;
}

.about-modal::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 30px;
}

.about-modal::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 50px; 
    margin-top: 30px;
}

.about-modal::-webkit-scrollbar-track {
    background: transparent;
}

.about-modal::-webkit-scrollbar-thumb {
    background-color: var(--darkyellow);
    border-radius: 10px;
    height: 10px;
}

.about-modal.active{
    transform: translate(-50%, -50%) scale(1);
}

.about-modal-body {
    padding: 0 40px 30px 40px;
}

.modal-close {
    margin-right: 10px;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    color: var(--textcolor);
    font-size: 2rem;
    font-weight: 400;
}

.modal-overlay {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    pointer-events: none;
    transition: 200ms ease-in-out;
}

.modal-overlay.active {
    opacity: 1;
    pointer-events: all;
}

@media (max-width: 1400px){
    .about-modal-body {
        font-size: 1.5rem;
    }
}


@media (max-width: 800px){
    #split{
        font-size: 3rem;
        line-height: 3.5rem;
    }
    .about-container p {
        width: 100%;
        letter-spacing: -2px;
    }
    .about-container .about-me {
        font-size: 0.75rem;
    }
    .about-modal-body {
        font-size: 1rem;
    }
}
